<template>
    <div style="background:#110133; width:100%; height:100vh; position:fixed; z-index:-20; top:0px;"></div>
    <div class="menu-desplegable" @click="desplegarMenuCochinito"></div>
    <div class="cuadro-oculto d-flex align-items-center flex-column" :style="`margin-left:${cuadroCochinito}`">
        <router-link to="/dashboard/cuenta/inicio" style="text-decoration: none; width:100%; perspective:300px;" v-if="cuentaSelect === true" @click="cambioSelect">
            <div class="contenedor-opcion d-flex justfify-content-center align-items-center">
                <p class="m-0 p-0 texto-black">Cuenta</p>
                <div class="fondo-select"></div>
                <div class="cuadro-select"></div>
                <div class="circulo-superior"></div>
                <div class="circulo-inferior"></div>
            </div>
        </router-link>
        <router-link to="/dashboard/cuenta/inicio" style="text-decoration: none;" v-if="cuentaSelect === false" @click="cambioSelect"><div class="contenedor-opcion">Cuenta</div></router-link>
        <router-link to="/dashboard/cuenta/banco" style="text-decoration: none; width:100%; perspective:300px;" v-if="bancoSelect === true" @click="cambioSelect">
            <div class="contenedor-opcion d-flex justfify-content-center align-items-center">
                <p class="m-0 p-0 texto-black">Bancos</p>
                <div class="fondo-select"></div>
                <div class="cuadro-select"></div>
                <div class="circulo-superior"></div>
                <div class="circulo-inferior2"></div>
            </div>
        </router-link>
        <router-link to="/dashboard/cuenta/banco" style="text-decoration: none;" v-if="bancoSelect === false" @click="cambioSelect"><div class="contenedor-opcion">Bancos</div></router-link>
        <router-link to="/dashboard/cuenta/inventario" style="text-decoration: none; width:100%; perspective:300px;" v-if="inventarioSelect === true" @click="cambioSelect">
            <div class="contenedor-opcion d-flex justfify-content-center align-items-center">
                <p class="m-0 p-0 texto-black">Inventarios</p>
                <div class="fondo-select"></div>
                <div class="cuadro-select"></div>
                <div class="circulo-superior"></div>
                <div class="circulo-inferior"></div>
            </div>
        </router-link>
        <router-link to="/dashboard/cuenta/inventario" style="text-decoration: none;" v-if="inventarioSelect === false" @click="cambioSelect"><div class="contenedor-opcion">Inventarios</div></router-link>
        <router-link to="/dashboard/cuenta/clientes" style="text-decoration: none; width:100%; perspective:300px;" v-if="clientesSelect === true" @click="cambioSelect">
            <div class="contenedor-opcion d-flex justfify-content-center align-items-center">
                <p class="m-0 p-0 texto-black">Clientes</p>
                <div class="fondo-select"></div>
                <div class="cuadro-select"></div>
                <div class="circulo-superior"></div>
                <div class="circulo-inferior2"></div>
            </div>
        </router-link>
        <router-link to="/dashboard/cuenta/clientes" style="text-decoration: none;" v-if="clientesSelect === false" @click="cambioSelect"><div class="contenedor-opcion">Clientes</div></router-link>
        <router-link to="/dashboard/cuenta/provedor" style="text-decoration: none; width:100%; perspective:300px;" v-if="provedorSelect === true" @click="cambioSelect">
            <div class="contenedor-opcion d-flex justfify-content-center align-items-center">
                <p class="m-0 p-0 texto-black">Provedores</p>
                <div class="fondo-select"></div>
                <div class="cuadro-select"></div>
                <div class="circulo-superior"></div>
                <div class="circulo-inferior"></div>
            </div>
        </router-link>
        <router-link to="/dashboard/cuenta/provedor" style="text-decoration: none;" v-if="provedorSelect === false" @click="cambioSelect"><div class="contenedor-opcion">Provedores</div></router-link>
        <router-link to="/dashboard/cuenta/caja" style="text-decoration: none; width:100%; perspective:300px;" v-if="cajaSelect === true" @click="cambioSelect">
            <div class="contenedor-opcion d-flex justfify-content-center align-items-center">
                <p class="m-0 p-0 texto-black">Cajas</p>
                <div class="fondo-select"></div>
                <div class="cuadro-select"></div>
                <div class="circulo-superior"></div>
                <div class="circulo-inferior2"></div>
            </div>
        </router-link>
        <router-link to="/dashboard/cuenta/caja" style="text-decoration: none;" v-if="cajaSelect === false" @click="cambioSelect"><div class="contenedor-opcion">Cajas</div></router-link>
    </div>
    <div class="contenedor-cochinito" :style="`width:${anchoContenedor}; margin-left:${marginLeftContenedor}; transition: all 0.5s;`">
        <router-view ref="view"/>
    </div>
</template>

<script>
    import Footer from "../../components/Footer.vue"
    import {mapState} from "vuex"

    export default {
        name: "App",
        components: {
            Footer
        },
        data(){
            return{
                //dato del contenedor de el dashboard
                marginLeftContenedor: '175px',
                anchoContenedor:`calc(100% - 175px)`,

                //menu desplegable de la izquierda
                cuadroCochinito: '0px',

                //select opcion de cochinito
                cuentaSelect: true,
                bancoSelect: false,
                inventarioSelect: false,
                clientesSelect: false,
                provedorSelect: false,
                cajaSelect: false,
            }
        },
        methods:{

            //desplegar menu desplegable

            desplegarMenuCochinito(){
                if(window.innerWidth > 900){
                    if(this.cuadroCochinito === '0px'){
                        this.cuadroCochinito = '-175px'
                        this.anchoContenedor = `100%`
                        this.marginLeftContenedor = '0px'
                    }else{
                        this.cuadroCochinito = '0px'
                        this.anchoContenedor = `calc(100% - 175px)`
                        this.marginLeftContenedor = '175px'
                    }
                }else{
                    if(this.cuadroCochinito === '0%'){
                        this.cuadroCochinito = '-100%'
                        this.anchoContenedor = `100%`
                        this.marginLeftContenedor = '0%'
                    }else{
                        this.cuadroCochinito = '0%'
                        this.anchoContenedor = `100%`
                        this.marginLeftContenedor = '0%'
                    }
                }
            },

            //cargar la pagina, loadpage

            loadInformation(){
                if(localStorage.getItem('uri2') === '1'){
                    this.cuentaSelect = true;
                    this.bancoSelect = false;
                    this.inventarioSelect = false;
                    this.clientesSelect = false;
                    this.provedorSelect = false;
                    this.cajaSelect = false;
                }
                if(localStorage.getItem('uri2') === '2'){
                    this.cuentaSelect = false;
                    this.bancoSelect = true;
                    this.inventarioSelect = false;
                    this.clientesSelect = false;
                    this.provedorSelect = false;
                    this.cajaSelect = false;
                }
                if(localStorage.getItem('uri2') === '3'){
                    this.cuentaSelect = false;
                    this.bancoSelect = false;
                    this.inventarioSelect = true;
                    this.clientesSelect = false;
                    this.provedorSelect = false;
                    this.cajaSelect = false;
                }
                if(localStorage.getItem('uri2') === '4'){
                    this.cuentaSelect = false;
                    this.bancoSelect = false;
                    this.inventarioSelect = false;
                    this.clientesSelect = true;
                    this.provedorSelect = false;
                    this.cajaSelect = false;
                }
                if(localStorage.getItem('uri2') === '5'){
                    this.cuentaSelect = false;
                    this.bancoSelect = false;
                    this.inventarioSelect = false;
                    this.clientesSelect = false;
                    this.provedorSelect = true;
                    this.cajaSelect = false;
                }
                if(localStorage.getItem('uri2') === '6'){
                    this.cuentaSelect = false;
                    this.bancoSelect = false;
                    this.inventarioSelect = false;
                    this.clientesSelect = false;
                    this.provedorSelect = false;
                    this.cajaSelect = true;
                }
            },

            //cambiar seleccionado cuando oprimen una opcion del menu

            cambioSelect(){
                setTimeout(() => {
                    if(localStorage.getItem('uri2') === '1'){
                        this.cuentaSelect = true;
                        this.bancoSelect = false;
                        this.inventarioSelect = false;
                        this.clientesSelect = false;
                        this.provedorSelect = false;
                        this.cajaSelect = false;
                        this.ocultador()
                    }
                    if(localStorage.getItem('uri2') === '2'){
                        this.cuentaSelect = false;
                        this.bancoSelect = true;
                        this.inventarioSelect = false;
                        this.clientesSelect = false;
                        this.provedorSelect = false;
                        this.cajaSelect = false;
                        this.ocultador()
                    }
                    if(localStorage.getItem('uri2') === '3'){
                        this.cuentaSelect = false;
                        this.bancoSelect = false;
                        this.inventarioSelect = true;
                        this.clientesSelect = false;
                        this.provedorSelect = false;
                        this.cajaSelect = false;
                        this.ocultador()
                    }
                    if(localStorage.getItem('uri2') === '4'){
                        this.cuentaSelect = false;
                        this.bancoSelect = false;
                        this.inventarioSelect = false;
                        this.clientesSelect = true;
                        this.provedorSelect = false;
                        this.cajaSelect = false;
                        this.ocultador()
                    }
                    if(localStorage.getItem('uri2') === '5'){
                        this.cuentaSelect = false;
                        this.bancoSelect = false;
                        this.inventarioSelect = false;
                        this.clientesSelect = false;
                        this.provedorSelect = true;
                        this.cajaSelect = false;
                        this.ocultador()
                    }
                    if(localStorage.getItem('uri2') === '6'){
                        this.cuentaSelect = false;
                        this.bancoSelect = false;
                        this.inventarioSelect = false;
                        this.clientesSelect = false;
                        this.provedorSelect = false;
                        this.cajaSelect = true;
                        this.ocultador()
                    }
                }, 20);
            },

            //ocultador de menu en version telefono 

            ocultador(){
                if(window.innerWidth < 900){
                    this.cuadroCochinito = '-100%'
                    this.anchoContenedor = `100%`
                    this.marginLeftContenedor = '0%'
                }
            }

        },
        created(){
            this.loadInformation()
            if(window.innerWidth > 900){
                this.cuadroCochinito = '0px'
                this.anchoContenedor = `calc(100% -175px)`
                this.marginLeftContenedor = '175px'
            }else{
                this.cuadroCochinito = '-100%',
                this.anchoContenedor = `100%`
                this.marginLeftContenedor = '0%'
            }
        },
        computed:{
        ...mapState(["token", "server"]),
        }
    }
</script>

<style scoped>
    .fondo-select{
        background:linear-gradient(90deg, #78B0B5 0%, #c7c7c7 100%);
        width: 90%;
        height: 35px;
        position: absolute;
        margin-left: 10%;
        border-radius:17.5px 0px 0px 17.5px;
        animation-duration: 1s;
        animation-name: select;
    }
    .circulo-superior{
        height: 31.5px;
        width: 31.5px;
        right: 0px;
        top:-37px;
        background:#110133;
        position: absolute;
        clip-path: circle(100.0% at 1% 0);
        transform: scaleX(1.1);
    }
    .circulo-inferior{
        height: 31.5px;
        width: 31.5px;
        right: 0px;
        top:29px;
        background:#110133;
        position: absolute;
        clip-path: circle(100.0% at 2% 100%);
        transform: scaleX(1.1);
    }
    .circulo-inferior2{
        height: 31.5px;
        width: 31.5px;
        right: 0px;
        top:30px;
        background:#110133;
        position: absolute;
        clip-path: circle(100.0% at 2% 100%);
        transform: scaleX(1.1);
    }
    .cuadro-select{
        position: absolute;
        background:linear-gradient(90deg, #78B0B5 0%, #c7c7c7 100%);
        height: 80px;
        width: 90%;
        margin-left: 10%;
        clip-path: inset(0 0 0 80%);
        animation-duration: 1s;
        animation-name: select;
    }
    @keyframes select {
        0%{
            transform: translateX(100%);
        }
        100%{
            transform:translateX(0%);
        }
    }
    .contenedor-opcion{
        color:white;
        text-align: center;
        font-family: Rubik;
        font-size: 16px;
        margin-top:30px;
        width: 100%;
        cursor: pointer;
        position: relative;
    }
    .texto-black{
        color: black;
        z-index: 10;
        text-align: center;
        font-family: Rubik;
        font-size: 16px;
        font-weight: bold;
        width: 100%;
        animation-duration: 1.5s;
        animation-name: selectColor;
    }
    @keyframes selectColor {
        0%{
            color: rgb(219, 219, 219);
        }
        100%{
            color: black;
        }
    }
    .cuadro-oculto{
        width: 175px;
        height: 100vh;
        position: fixed;
        background:#110133;
        transition: all 0.5s;
        box-shadow: 5px -4px 40px rgba(0, 0, 0, 0.5);
        padding-top:75px ;
        top: 0px;
        overflow: hidden;
        z-index: 60;
    }
    .menu-desplegable{
        position: fixed;
        top: 0px;
        left: 0px;
        width: 40px;
        height: 40px;
        background-image: url(../../assets/pictures/despliegue.svg);
        z-index: 100;
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: 15px;
        margin-left: 67.5px;
        cursor: pointer;
    }
    .contenedor-cochinito{
        height: auto;
        width:calc(100% - 175px); 
        margin-left:0px;
    }
    .link-recover{
        width: 150px;
        text-align: center;
        font-family: Rubik;
        font-weight:bold ;
        font-size: 16px;
        margin-top: 25px;
        color: #297F87;
        text-decoration: none;
    }
    .contenedor-recover{
        position: relative;
        height: 600px;
        width: 100%;
        overflow: hidden;
    }
    .titulo-recover{
        font-family: Rubik;
        font-weight: bold;
        margin-top:80px ;
        font-size: 55px;
    }
    .subtitulo-recover{
        font-family: Rubik;
        margin-top:5px ;
        font-weight: bold;
        font-size: 25px;
    }
    .form-recover{
        width: 50%;
        margin-top: 45px;
    }
    .input,.input2{
        border-radius:10px;
        border: #6464646b 2px solid;
        width: 50px;
        height: 50px;
        font-size:40px ;
        text-align: center;
        padding-left: 3px;
        padding-top: 3px;
        padding-bottom: 3px;
    }
    .input2{
        margin-left:35px ;
    }
    .button-recover{
        width: 40%;
        height: 40px;
        border:none;
        border-radius:10px;
        background: #297F87;
        color: white;
        text-align: center;
        font-family: Rubik;
        font-size:20px ;
        margin-top: 50px;
    }
    @media (max-width: 900px) { 
        .cuadro-oculto{
            width:100%;
        }
        .cuadro-select{
            width: 160px;
            margin-left: calc(100% - 160px);
        }
    }
    @media (max-width: 600px) { 
        .menu-desplegable{
            width: 30px;
            height: 30px;
            margin-top: 18px;
            margin-left: 47.5px;
        }
    }
</style>